/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";

import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";
import Footer from "~components/Footer.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import ContactForm from "~components/ContactForm.jsx";

const LetsTalk = ({ data, location }) => {
  const cms = data.sanityLetsTalkPage;

  const { backgroundColor, textColor, header, subheading } = cms;

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout>
        <section
          css={[
            css`
              background-color: ${backgroundColor?.hex || `white`};
              color: ${textColor?.hex || `black`};

              .wFormContainer .wFormHeader {
                border: 10px solid red !important;
              }
            `,
            tw`relative flex items-center justify-center`
          ]}
        >
          <iframe
            src="https://tfaforms.com/5051010"
            height="1500"
            width="600"
            frameborder="0"
          ></iframe>
          {/* <script src="//tfaforms.com/js/iframe_resize_helper.js"></script> */}

          {/* <div
            css={[
              css`
                width: 50vw;
              `,
              tw`h-full absolute top-0 right-0 bottom-0 hidden md:block bg-purple-dark`
            ]}
          />

          <Grid>
            <div css={[css``, tw`col-span-24 md:col-span-12 relative`]}>
              <header tw="px-0 py-12 md:px-[60px] md:py-[72px]">
                <T.Head level="1" font="1" _css={[tw`mb-12`]}>
                  {header}
                </T.Head>

                <T.Body font="h4">{subheading}</T.Body>
              </header>
            </div>

            <div
              css={[
                css``,
                tw`col-span-24 md:col-span-12 md:col-start-13 relative bg-purple-dark text-purple-light`
              ]}
            >
              <div
                css={[
                  css`
                    width: calc(100% + 2rem);
                    left: -1rem;
                  `,
                  tw`absolute top-0 bottom-0 md:hidden bg-purple-dark`
                ]}
              />

              <ContactForm />
            </div>
          </Grid> */}
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default LetsTalk;

export const query = graphql`
  query LetsTalkPage {
    sanityLetsTalkPage {
      title

      textColor {
        hex
      }
      backgroundColor {
        hex
      }
      header
      subheading

      seoDescription
      seoKeywords
    }
  }
`;
